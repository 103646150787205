<template>
  <VDialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <VCard>
      <VForm @submit.prevent="send">
        <VListItem>
          <VListItemTitle class="headline">
            {{ $t('$app.chat_create.title') }}
          </VListItemTitle>
          <VListItemAction>
            <VBtn
              :title="$t('close.one')"
              icon
              :to="{ name: 'messenger' }"
              exact
            >
              <VIcon>close</VIcon>
            </VBtn>
          </VListItemAction>
        </VListItem>

        <VCardText>
          <VTextField
            v-model="title"
            max-length="1024"
            outlined
            :label="$t('subjects.one')"
          />

          <VAutocomplete
            v-model="course"
            :items="courses"
            item-text="title"
            item-value="id"
            :label="$t('courses.one')"
            outlined
          />

          <VTextarea
            v-model.trim="body"
            :label="$t('messages.one')"
            required
            outlined
            hide-details
            @keydown.enter.prevent.exact="send"
            @keydown.shift.enter.prevent.exact="addLineBreak"
          />
        </VCardText>
        <FileList v-model="files" />
        <VCardSubtitle class="caption">
          {{ $t('$app.chat_create.filesize_msg', { n: 5 }) }}
        </VCardSubtitle>

        <VCardActions class="actions px-sm-4">
          <VSpacer />
          <VBtn
            :title="$t('attach_files.one')"
            text
            large
            tag="label"
          >
            <VIcon
              left
              size="24"
            >
              attach_file
            </VIcon>
            <span>{{ $t( 'attach_files.one') }}</span>
            <input
              v-file="files"
              type="file"
              hidden
              :accept="accept"
            >
          </VBtn>
          <VBtn
            :title="$t('send.one')"
            type="submit"
            color="success"
            large
            :disabled="!body"
            :loading="isSubmitting"
          >
            {{ $t('send.one') }}
            <VIcon right>
              send
            </VIcon>
          </VBtn>
        </VCardActions>
      </VForm>
    </VCard>
  </VDialog>
</template>

<script>
import { sendMessage, getAvailableCourses } from '@/api'
import FileList from '@/components/FileList.vue'
import { accept } from '@/utils/fileAccepts'

export default {
  name: 'UMessengerForm',
  components: {
    FileList
  },

  props: {
    lessonTitle: {
      type: String,
      default: ''
    },

    courseID: {
      type: Number,
      default: 0
    },

    courseTitle: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      files: [],
      accept,
      title: this.courseTitle,
      initialCourses: [],
      isSubmitting: false,
      dialog: true,
      course: this.courseID,
      body: this.lessonTitle ? `Здравствуйте, вопрос по уроку "${this.lessonTitle}": ` : ''
    }
  },

  computed: {
    courses () {
      return [{
        title: this.$t('messages_general_question.one'),
        id: 0
      }, ...this.initialCourses]
    }
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.$router.push({ name: 'messenger' }).catch(() => {})
      }
    }
  },

  created () {
    this.register()
  },

  methods: {
    addLineBreak () {
      this.body += '\n'
    },

    async send () {
      if (!this.body.length) { return }
      this.isSubmitting = true

      const formData = new FormData()
      formData.append('title', this.title)
      formData.append('body', this.body)
      formData.append('course', this.course)
      this.files.forEach(f => formData.append('files', f, f.name))

      try {
        const { chat } = await sendMessage(0, 0, formData)
        this.$router.push({ name: 'messenger', query: { chat: chat.id } }).catch(() => {})
        this.reset()
      } finally {
        this.isSubmitting = false
      }
    },

    reset () {
      this.body = ''
      this.files.splice(0, this.files.length)
      this.title = ''
      this.course = 0
    },

    async loadCourses () {
      const { courses } = await getAvailableCourses()
      this.initialCourses = courses || []
    },

    register () {
      this.loadCourses()
    }
  }
}
</script>
